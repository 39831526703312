@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';

@primary-color: #4f45f4;
@secondary-color: #f56b21;
@tertiary-color: #ea9af2;
@primary-text-color: #171717;

.ant-tooltip-inner {
  border-radius: 5px;
}

.ant-select-selection-item {
  flex: none;
}

.ant-select-single .ant-select-selector {
  align-items: center;
}

.select-dropdown-z {
  z-index: 9999;
}

.ant-select-selector {
  margin-top: 4px !important;
}

.ant-select-arrow {
  margin-top: -2px;
}

.ant-pagination-item a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.ant-pagination-jump-next {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: var(--primary-text-color);
}

.ant-switch-checked {
  background-color: var(--secondary-color);
}

.ant-steps-item-active .ant-steps-item-subtitle {
  color: #000;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: var(--secondary-color);
}

.ant-steps-item-process .ant-steps-item-icon {
  border-color: var(--secondary-color);
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: white;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: var(--primary-color);
}

.text-gray {
  color: #939393;
}

.link-style {
  color: var(--secondary-color) !important;
  text-decoration: underline !important;
}

.ant-tabs-ink-bar {
  background-color: var(--secondary-color);
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--secondary-color);
}

.ant-tabs-tab-btn:hover {
  color: var(--secondary-color);
}

.ant-drawer-body.ant-drawer-body {
  padding: 2rem;
}

.custom-copy-icon {
  color: var(--secondary-color) !important;
}
