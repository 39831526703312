@import 'reset.css';
@import 'icons.css';
@import 'intro.css';
@import '~tailwindcss/tailwind.css';
@import 'nprogress/nprogress.css';

@font-face {
  font-family: 'Roobert';
  src: url('../../public/fonts/Roobert-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Roobert';
  src: url('../../public/fonts/Roobert-Medium.ttf');
  font-weight: 500;
}

* {
  box-sizing: border-box;
  font-family: 'Roobert', 'Helvetica', sans-serif !important;
  line-height: 1.3em;
  margin: 0;
  padding: 0;
}

:root {
  --primary-color: #4f45f4;
  --secondary-color: #f56b21;
  --tertiary-color: #ea9af2;

  --primary-text-color: #171717;
  --secondary-text-color: #fff;
  --tertiary-text-color: #6f6f6f;

  --primary-background-color: #fff;
  --secondary-background-color: #fafafa;

  --primary-border-color: #f0f0f0;

  --danger-color: #ff5630;
  --pending-color: #ffab00;
  --success-color: #36b37e;
  --danger-bg-color: RGB(0, 184, 217, 0.4);
  --pending-bg-color: RGB(255, 171, 0, 0.4);
  --success-bg-color: RGB(54, 179, 126, 0.4);
  --processing-color: #00b8d9;
  --unavailable-color: #dfe1e6;
  --toastify-text-color-error: #fff;
}

hr {
  opacity: 30%;
}

h1 {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.38;
  color: var(--secondary-text-color);
}

h2 {
  font-size: 26px;
  font-weight: 400;
  margin: 0;
  color: var(--primary-text-color);

  @media (max-width: 750px) {
    font-size: 24px;
  }
}

h3 {
  font-size: 22px;
  font-weight: 400;
  color: var(--primary-text-color);
  @media (max-width: 750px) {
    font-size: 20px;
  }
}

h4 {
  font-size: 20px;
  color: var(--primary-text-color);
  font-weight: 400;
}

h5 {
  font-size: 18px;
  font-weight: 400;
  color: var(--primary-text-color);
}

h6 {
  font-size: 16px;
  font-weight: 400;
  color: var(--primary-text-color);
}

p,
span {
  font-size: 14px;
}

textarea {
  font-size: 14px;
  color: var(--primary-text-color);
}

a:hover {
  color: inherit;
  opacity: 95%;
}

:hover {
  filter: none !important;
}

input {
  background-color: transparent;
}

input:focus {
  border: 1px solid var(--primary-color) !important;
}

button {
  cursor: pointer;
  background: transparent;
  border: none;
}

button:hover {
  opacity: 95%;
}
button:focus {
  outline: 0;
  box-shadow: 0 0 0 3px rgba(21, 52, 228, 0.3);
}

input:checked {
  background-color: black !important;
}

.btn-responsive:focus {
  @media (max-width: 750px) {
    outline: 0;
    box-shadow: none;
  }
}

.label {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  color: var(--primary-text-color);
}

::placeholder {
  font-size: 14px;
  color: var(--tertiary-text-color);
  align-items: left;
  font-weight: 400;
}

.active,
.active:hover {
  font-weight: 500;
}

.activeIcon i {
  color: var(--primary-color) !important;
}
.customButton,
.activeButton {
  font-size: 0.9rem;
  padding: 0px 10px 10px 10px;
  color: var(--primary-text-color);
  border: none;
  background-color: transparent;
  @media (max-width: 450px) {
    padding: 0px 20px 10px 0px;
  }
}

.activeButton {
  font-weight: bold;
  color: var(--primary-color);
}
a,
li {
  color: inherit;
}

li:visited,
a:visited {
  color: inherit;
}

li:hover {
  cursor: pointer;
}

a:hover {
  cursor: pointer;
}

textarea {
  resize: none;
  outline: none;
  border: none;
  overflow: auto;
  height: 20px;
  text-align: right;
}

.main-container {
  position: relative;
  display: flex;
  z-index: 999;
}

.section-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 120px 5% 100px 25%;
  @media (max-width: 1024px) {
    width: 100%;
    padding: 140px 4% 100px 4%;
  }
  @media (max-width: 750px) {
    padding-top: 140px;
  }
}
.section-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 20px;

  &div {
    width: 100%;
    @media (max-width: 720px) {
      width: 55%;
    }
  }
  .button-desktop p {
    @media (max-width: 720px) {
      display: none;
    }
  }

  @media (max-width: 720px) {
    .button-desktop button {
      border: none !important;
      background-color: transparent !important;
      text-align: right !important;
      cursor: pointer;
      i {
        font-size: 20px !important;
      }
    }

    button a {
      display: none;
    }
  }

  > h2 {
    padding: 0;
  }
}

.env-label-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5% 0 25%;
  @media (max-width: 1024px) {
    width: 100%;
    padding: 0 4% 0 4%;
  }
}

.table {
  width: 100%;
  margin: 0 auto;
  position: relative;

  .delete-api-icon {
    width: 90%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    bottom: 40%;
  }
  .selectedRowStyles tbody tr.selected {
    color: red;
    background-color: green;
  }
}
.selectedItem {
  font-weight: bold;
}

button.no-border-icon {
  border: none !important;
  width: 44px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

// .ant-table {
//   overflow-x: scroll;
// }

//navigation

@media (max-width: 520px) {
  .nav-navbar {
    width: 100%;
  }
}

.icon {
  margin: 0 5px 0 5px;
  justify-content: center;
  align-items: center;
  width: auto !important;
}

.icon:hover {
  opacity: 95%;
  cursor: pointer;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 21px;
  height: 15px;
  left: 36px;
  top: 15px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: var(--primary-color);
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #686868;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 40px !important;
  width: 40px !important;
  margin: 10px 10px 0 0;
}

/* Color/shape of close button cross */
.bm-cross {
  background: var(--secondary-text-color);
  background-color: inherit !important;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: var(--primary-color);
  padding: 1.3em 1.5em 0;
  font-size: 16px;
  font-weight: 500;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

//auth pages
.login-bg {
  background: url('../../public/img/LoginBg.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.scrollable {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  padding: 8% 4rem 40px 4rem;
  @media (max-width: 940px) {
    width: 100%;
    margin: 0 auto;
    padding-top: 20%;
  }
  @media (max-width: 720px) {
    width: 100%;
    margin: 0 auto;
    padding-top: 20%;
  }
}

.plans-container {
  border: 2px solid gainsboro;
}

//policy page
.policy-table td {
  border-bottom: 1px solid rgb(199, 199, 199);
  padding: 15px;
}

.react-select {
  &__control {
    border-radius: 5px !important;

    :focus {
      color: var(--primary-text-color) !important;
      border: none !important;
    }
  }

  &__menu {
    position: absolute;
    width: 100%;
    z-index: 100;
  }
}

.currentStatus {
  margin: 0 auto;
}

i.icon.outline {
  font-family: 'outline-icons' !important;
}

@media only screen and (max-width: 650px) {
  .title-heading {
    font-size: 18px !important;
    text-align: center !important;
  }
}

.email-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 650px) {
    height: 100%;
    display: block;
  }
}

//toast

.Toastify__toast-body * {
  color: white !important;
}

.Toastify__close-button * {
  color: white !important;
}

button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.MuiTab-fullWidth:focus {
  box-shadow: none !important;
}

.nohover:hover {
  cursor: inherit !important;
  opacity: 100%;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background-color: #e74c3c !important;
  font-size: 15px !important;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: #5abf92 !important;
  font-size: 15px !important;
}

//intro

.introjs-tooltip {
  max-width: 40vw !important;

  padding: 5px 25px;

  background-position: center;

  background-size: contain;

  background-position-y: bottom;

  background-repeat: no-repeat;

  background-position-x: right;
}

.introjs-tooltiptext {
  margin-bottom: 25px;

  width: 60%;
}

.introjs-tooltipbuttons {
  border-top: 0px !important;

  display: flex;

  justify-content: flex-end;
}

.introjs-button.introjs-nextbutton {
  background: var(--secondary-color);

  color: var(--secondary-text-color);

  text-shadow: none;

  margin-left: 20px;
}

.introjs-button.introjs-nextbutton.introjs-button:focus {
  background: var(--secondary-color);

  color: var(--secondary-text-color);

  text-shadow: none;
}
//blockchain && developers charts
.chart {
  width: 100%;
  height: 320px;
  padding: 5px 15px;
  margin: 5px 10px;
  background-color: #f8f8fb;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 1080px) {
    width: 100%;
    height: 360px;
    margin: 8px 0;
  }
  @media (max-width: 420px) {
    height: 300px;
  }
}

//intro
.introjs-tooltip {
  max-width: 40vw !important;
  padding: 5px 25px;
  background-position: center;
  background-size: contain;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-position-x: right;
  background-color: var(--primary-color);
  color: var(--secondary-text-color);
  line-height: 1.2rem;

  h3 {
    color: var(--secondary-text-color);
  }

  ul {
    list-style-type: circle;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 20px;
    padding-left: 20px;
    line-height: 1.4rem;
    font-size: 14px;
  }
}

.introjs-tooltiptext {
  margin-bottom: 25px;
  width: 70%;
}

.firstTooltip {
  background-image: url('../../public/img/tooltip_bg_1.png') !important;
  width: 30vw;
  background-position: center;
  background-size: 58%;
  background-repeat: no-repeat;
  background-position-x: right;
}

.introjs-tooltipbuttons {
  border-top: 0px !important;
  display: flex;
  justify-content: flex-end;
}

.introjs-button.introjs-nextbutton {
  background: var(--secondary-color);
  color: var(--secondary-text-color);
  text-shadow: none;
  margin-left: 20px;
}

.introjs-button.introjs-nextbutton.introjs-button:focus {
  background: var(--secondary-color);
  color: var(--secondary-text-color);
  text-shadow: none;
}

.myHighlightClass {
  box-shadow: rgb(33 33 33 / 80%) 0px 0px 1px 0px, rgb(33 33 33 / 50%) 0px 0px 0px 5000px !important;
}

.firstTooltip {
  background-image: url('../../public/img/tooltip_bg_1.png') !important;
  width: 30vw;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: right;
}

.ant-spin-dot-item {
  background-color: var(--primary-background-color);
  opacity: 0.8;
}

.ant-spin.ant-spin-sm.ant-spin-spinning.negative
  .ant-spin-dot.ant-spin-dot-spin
  .ant-spin-dot-item {
  background-color: var(--primary-text-color);
  opacity: 0.8;
}

.table-tag {
  margin-right: 0;
  font-size: 15px;
}

.select-dropdown-z {
  z-index: 9999;
}

.ant-menu-title-content {
  color: white;
  font-weight: 100;
}

.ant-menu-submenu-arrow {
  color: white;
}

.left-navbar {
  background-color: var(--primary-color) !important;
  min-height: 40vh;
}

.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark {
  background-color: var(--primary-color);
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background-color: var(--primary-color);
}

.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
  background-color: #8983f8 !important;
}

.ant-menu-item:hover {
  background-color: #6560f0 !important;
}

.ant-menu-submenu-title:hover {
  background-color: #6560f0 !important;
}

.ant-menu-title-content {
  color: white;
  font-weight: 100;
}

.barsMenu {
  float: left;
  height: 42px;
  padding: 6px;
  display: block;
  z-index: 50;
  top: 10%;
  float: left;
  padding: 6px;
  margin-top: 8px;
  width: 42px;
  left: 10%;
  border: none;
}

.ant-drawer-body {
  padding: 0;
}

.barsMenu > span {
  display: block;
  color: #000000;
}

.burger-menu-drawer.ant-drawer-open {
  // width: 100% !important; // mask: false
  > .ant-drawer-content-wrapper {
    position: absolute;
    height: 100vh;
  }
  > .ant-drawer-content-wrapper .ant-drawer-wrapper-body .ant-drawer-header {
    background-color: var(--primary-color) !important;
    display: flex;
    flex-direction: row-reverse;
    border: none;
  }
}

@media (max-width: 500.98px) {
  .burger-menu-drawer.ant-drawer-open {
    > .ant-drawer-content-wrapper {
      width: 320px !important;
    }
  }
}

.ant-upload.ant-upload-drag .ant-upload {
  padding: 30px 0 !important;
}

.ant-upload-drag-icon {
  margin-bottom: 0 !important;
}

.ant-upload-list,
.ant-upload-list-text {
  display: none;
}

//nextinvoice table
table {
  width: 100%;
  border-collapse: collapse;
}
td {
  padding: 12px 0 12px 15px;
  border: 0px solid #eee;
  vertical-align: middle;
}
.tabletitle {
  padding: 5px;
  background: #fafafa;
}
.invoice_line {
  border-bottom: 1px solid #eee;
}
.invoice_col_3 p,
.invoice_col_4 p,
.invoice_col_5 p,
tfoot > tr > td > p {
  text-align: right;
}

tbody {
  margin-bottom: 20px;
}
.item {
  width: 50%;
}
.quantity {
  width: 15%;
}

//collapsible
.usage-collapse.ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box,
.usage-collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0 !important;
}
.usage-collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  font-size: 10px;
  margin-right: 5px;
}
.usage-collapse.ant-collapse > .ant-collapse-item:last-child,
.usage-collapse.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  font-size: 12px !important;
  color: var(--tertiary-text-color);
}

.plans-form > input:checked {
  background-color: red;
}

.plans-form input:hover {
  opacity: 0.4;
}

.plans-selector .ant-radio-inner,
.addons-selector .ant-checkbox {
  display: none;
}

.ant-radio-wrapper-checked .card-container,
.checkbox-checked .addon-container {
  border-radius: 10px;
  border: 1px solid var(--secondary-color);
}

.ant-radio-wrapper-checked .limit-radio {
  border: 1px solid var(--secondary-color);
}

.limit-radio {
  border: 1px solid #dedcdc;
}

.ant-radio-wrapper .plan-button {
  background-color: transparent !important;
  border: 1px solid var(--tertiary-text-color);
  color: var(--primary-text-color);
  width: 100%;
  margin: 15px auto;
  border-radius: 5px;
  padding: 11px;
}

.ant-radio-wrapper .addon-button,
.ant-checkbox-wrapper span:nth-child(2) div:nth-child(3),
.radio-checked {
  background-color: transparent !important;
  border: 1px solid var(--tertiary-text-color);
  height: 17px;
  width: 25%;
  padding: 20px 10px;
  margin: auto 30px auto 20px;
  border-radius: 5px;
  @media (max-width: 768px) {
    width: 100%;
    margin: 20px 30px 5px 30px;
  }
}

.ant-radio-wrapper-checked .plan-button,
.checkbox-checked span:nth-child(2) div:nth-child(3),
.radio-checked {
  background-color: var(--secondary-color) !important;
  color: var(--secondary-text-color) !important;
  border: 1px solid var(--secondary-color);
}

.ant-radio-wrapper-checked .plan-title,
.checkbox-title-checked .addon-title {
  color: var(--secondary-color) !important;
}

.plans-container {
  border: none;
  display: block;
  flex: 0 0 33.3333333%;
  max-width: 33.333333%;
  padding-right: 18px;
  @media (max-width: 768px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (max-width: 650px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.plans-selector,
.addons-selector,
.plans-selector label span:nth-child(2),
.addons-selector span:nth-child(2),
.plans-selector .ant-checkbox-group label span:nth-child(2),
.addons-selector .ant-checkbox-group label span:nth-child(2) {
  width: 100% !important;
  padding: 0px !important;
}

// .ant-checkbox-wrapper,
// .ant-checkbox-group-item,
// .ant-checkbox-group,
// .addons-checkbox,
// .ant-checkbox-wrapper-checked {
//   width: 100% !important;
// }

tfoot {
  margin-top: 20px !important;
  padding-top: 30px !important;
}
tfoot > tr {
  height: 30px !important;
  padding: 0;
}

tfoot > tr > td {
  height: 30px !important;
  padding: 0;
}

.ant-table-tbody > tr > td {
  min-width: 120px;
}

.tabletitle td:nth-child(4),
.tabletitle td:nth-child(5) {
  width: 12%;
}

.tabletitle td:nth-child(3) p,
.tabletitle td:nth-child(4) p,
.tabletitle td:nth-child(5) p {
  text-align: right;
}

.ant-alert-description {
  line-height: 1.3 !important;
}

.product-list:last-child hr {
  display: none;
}

.MuiFormControlLabel-root {
  margin-right: 5px !important;
}

.features-collapse-item > header,
.features-collapse,
.features-collapse-item div:nth-child(2) {
  border: none !important;
}

.features-collapse-item {
  border: 1px solid #d9d9d9;
  padding: 10px !important;
  margin-bottom: 10px !important;
  border-radius: 5px !important;
}

.features-collapse {
  background-color: transparent !important;
}

.custom-select {
  min-width: 200px;
}

@media (max-width: 347px) {
  .custom-select {
    min-width: 100%;
  }
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 5px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  margin-right: 20px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input)
  .ant-select-selection-search-input {
  border: none !important;
  box-shadow: none !important;
}

.auth-tabs {
  width: 100%;
  min-height: 200px;
}

.feature-radio-input {
  border-radius: 5px;
  padding: 9px 11px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-text-color);
  color: var(--primary-text-color);
}

.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-checked .ant-checkbox-inner {
  color: var(--primary-text-color);
  border-color: var(--primary-text-color);
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: var(--primary-text-color);
  color: var(--primary-text-color);
}

.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-checked .ant-checkbox-inner {
  color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--secondary-color) !important;
}

.ant-form-item-label > label {
  color: var(--tertiary-text-color);
}

.ant-radio-checked .ant-radio-inner {
  color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
}
.ant-radio-inner::after {
  background-color: var(--secondary-color) !important;
}

.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-checked .ant-checkbox-inner {
  color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--secondary-color) !important;
}

.ant-form-item-label > label {
  color: var(--tertiary-text-color);
}

.ant-radio-checked .ant-radio-inner {
  color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
}
.ant-radio-inner::after {
  background-color: var(--secondary-color) !important;
}
