.errorTemp {
  width: 33%;
  left: 59%;
  position: absolute;
  top: 8%;
  z-index: 999;
  @media (max-width: 1080px) {
    width: 50%;
    left: 50%;
    padding: 0px 20px;
  }
  @media (max-width: 414px) {
    width: 89%;
    top: 14%;
  }
}
