@font-face {
    font-family: "icons";
    src: url("../../public/fonts/icons.ttf?e8c2f9ef58fb801dadcedcc949a5c2df") format("truetype"),
url("../../public/fonts/icons.woff?e8c2f9ef58fb801dadcedcc949a5c2df") format("woff"),
url("../../public/fonts/icons.woff2?e8c2f9ef58fb801dadcedcc949a5c2df") format("woff2");
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-add-black-icon:before {
    content: "\f101";
}
.icon-add-icon-white:before {
    content: "\f102";
}
.icon-add-white-icon:before {
    content: "\f103";
}
.icon-api-icon:before {
    content: "\f104";
}
.icon-back:before {
    content: "\f105";
}
.icon-billing:before {
    content: "\f106";
}
.icon-blockchain:before {
    content: "\f107";
}
.icon-bloock-logo-blue:before {
    content: "\f108";
}
.icon-bloock-logo-negative:before {
    content: "\f109";
}
.icon-bloock-logo:before {
    content: "\f10a";
}
.icon-bloock:before {
    content: "\f10b";
}
.icon-cactus:before {
    content: "\f10c";
}
.icon-calendar-icon:before {
    content: "\f10d";
}
.icon-cancel-icon:before {
    content: "\f10e";
}
.icon-check-mark-black-icon:before {
    content: "\f10f";
}
.icon-check:before {
    content: "\f110";
}
.icon-close_1:before {
    content: "\f111";
}
.icon-close:before {
    content: "\f112";
}
.icon-copy-icon:before {
    content: "\f113";
}
.icon-credit-card:before {
    content: "\f114";
}
.icon-cross-black-icon:before {
    content: "\f115";
}
.icon-dashboard-icon:before {
    content: "\f116";
}
.icon-delete_1:before {
    content: "\f117";
}
.icon-delete:before {
    content: "\f118";
}
.icon-developers-icon:before {
    content: "\f119";
}
.icon-docs-icon:before {
    content: "\f11a";
}
.icon-download:before {
    content: "\f11b";
}
.icon-edit-icon:before {
    content: "\f11c";
}
.icon-empty:before {
    content: "\f11d";
}
.icon-error-icon:before {
    content: "\f11e";
}
.icon-error-status:before {
    content: "\f11f";
}
.icon-exclamation-error-icon:before {
    content: "\f120";
}
.icon-explorer-icon:before {
    content: "\f121";
}
.icon-feedback:before {
    content: "\f122";
}
.icon-gear:before {
    content: "\f123";
}
.icon-github-icon:before {
    content: "\f124";
}
.icon-go:before {
    content: "\f125";
}
.icon-help-icon:before {
    content: "\f126";
}
.icon-info-icon:before {
    content: "\f127";
}
.icon-invoice:before {
    content: "\f128";
}
.icon-java:before {
    content: "\f129";
}
.icon-js-icon:before {
    content: "\f12a";
}
.icon-js:before {
    content: "\f12b";
}
.icon-link-icon:before {
    content: "\f12c";
}
.icon-linkedin-icon:before {
    content: "\f12d";
}
.icon-loading-icon:before {
    content: "\f12e";
}
.icon-loading:before {
    content: "\f12f";
}
.icon-logout-icon:before {
    content: "\f130";
}
.icon-menu-burger-icon:before {
    content: "\f131";
}
.icon-message:before {
    content: "\f132";
}
.icon-next-arrow:before {
    content: "\f133";
}
.icon-next:before {
    content: "\f134";
}
.icon-nextjs-icon:before {
    content: "\f135";
}
.icon-pankod-icon:before {
    content: "\f136";
}
.icon-pending-status:before {
    content: "\f137";
}
.icon-php-icon:before {
    content: "\f138";
}
.icon-php:before {
    content: "\f139";
}
.icon-processing-status:before {
    content: "\f13a";
}
.icon-python:before {
    content: "\f13b";
}
.icon-refresh:before {
    content: "\f13c";
}
.icon-see-pass-icon:before {
    content: "\f13d";
}
.icon-settings:before {
    content: "\f13e";
}
.icon-status-icon:before {
    content: "\f13f";
}
.icon-success-icon:before {
    content: "\f140";
}
.icon-tick:before {
    content: "\f141";
}
.icon-trash-icon:before {
    content: "\f142";
}
.icon-twitter-icon:before {
    content: "\f143";
}
.icon-user-icon:before {
    content: "\f144";
}
.icon-webhooks-icon:before {
    content: "\f145";
}
.icon-youtube-icon:before {
    content: "\f146";
}
